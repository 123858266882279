//@author: devin

import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewEncapsulation,
} from "@angular/core";
import { MultiselectModelI } from "./s25.multiselect.component";
import { Api } from "../../api/api";
import { NgbPlacementArray } from "../s25-popover/popover.component";
import { TypeManagerDecorator } from "../../main/type.map.service";

export class MultiselectPopupApi extends Api {
    static close(relativeElem: HTMLElement, uuid: string) {
        return MultiselectPopupApi.callApiFn(
            relativeElem,
            "s25-ng-multiselect-popup",
            "forceClose",
            null,
            null,
            (comp, i) => {
                return comp && comp.modelBean && comp.modelBean.uuid === uuid;
            },
        );
    }
}

@TypeManagerDecorator("s25-ng-multiselect-popup")
@Component({
    selector: "s25-ng-multiselect-popup",
    template: `
        <div class="s25-multiselect-popup-position-header">
            @if (init) {
                <s25-popover
                    class="s25-multiselect-popup-placeholder"
                    [modelBean]="modelBean"
                    [openTrigger]="openTrigger"
                    [onBody]="popoverOnBody"
                    [closeTrigger]="closeTrigger"
                    [placement]="popoverPlacement"
                    (shown)="onOpen()"
                    (hidden)="onClose()"
                >
                    @if (!this.modelBean.textButton) {
                        <button class="aw-button {{ modelBean.buttonClass }} multiSelectButton" [disabled]="disabled">
                            {{ modelBean.buttonText }}
                        </button>
                    }
                    @if (modelBean.textButton) {
                        <button class="c-textButton" [disabled]="disabled">{{ modelBean.title }} +</button>
                    }
                </s25-popover>
            }
        </div>
        <ng-container
            [ngTemplateOutlet]="resultsTemplate || multiselectResults"
            [ngTemplateOutletContext]="{ modelBean: modelBean }"
        ></ng-container>

        <ng-template #multiselectResults let-modelBean="modelBean">
            @if (init && modelBean.showResult) {
                <s25-ng-multiselect-results [modelBean]="modelBean"></s25-ng-multiselect-results>
            }
        </ng-template>

        <ng-template #defaultPopoverTemplate>
            <s25-ng-multiselect [modelBean]="modelBean"></s25-ng-multiselect>
        </ng-template>
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25MultiselectPopupComponent implements OnInit, AfterViewInit {
    @Input() modelBean: MultiselectModelI;
    @Input() trigger: "hover" | "click" = "click";
    @Input() popoverTemplate: TemplateRef<any>;
    @Input() popoverPlacement: NgbPlacementArray = "auto";
    @Input() popoverOnBody = false;
    @Input() customPopoverClass?: string;
    @Input() resultsTemplate: TemplateRef<unknown>;
    @Input() disabled: boolean;

    @Output() close = new EventEmitter<void>();

    @ViewChild("defaultPopoverTemplate") defaultPopoverTemplate: TemplateRef<any>;

    init: boolean = false;
    openTrigger: "mouseenter" | "click";
    closeTrigger: "mouseleave" | "click";

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {
        this.elementRef.nativeElement.angBridge = this;
    }

    onOpen = () => {};

    onClose = () => {
        if (!this.modelBean.closing) {
            this.modelBean.cancel(true); //cancel but skip calling popup close since we are already closed here
        } else {
            this.modelBean.closing = false;
        }
        this.close.emit();
    };

    forceClose = () => {
        this.modelBean.closing = true;
        this.modelBean.forceClosePopup();
    };

    ngOnInit() {
        this.openTrigger = this.trigger === "hover" ? "mouseenter" : "click";
        this.closeTrigger = this.trigger === "hover" ? "mouseleave" : "click";
    }

    ngAfterViewInit() {
        this.zone.run(() => {
            this.modelBean.popoverTemplate = this.popoverTemplate || this.defaultPopoverTemplate;
            this.modelBean.popoverClass = this.customPopoverClass ? this.customPopoverClass : "s25-multiselect-popup";
            this.modelBean.buttonText = this.modelBean.buttonText || `Select ${this.modelBean.title}`;
            this.modelBean.buttonClass = this.modelBean.buttonClass || "aw-button--outline";
            this.init = true;
            this.cd.detectChanges();
        });
    }
}
