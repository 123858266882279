//@author: devin

import { MultiselectApi, MultiselectModelI } from "./s25.multiselect.component";
import { S25ItemI } from "../../pojo/S25ItemI";
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { S25LoadingApi } from "../s25-loading/loading.api";
import { MultiselectResultsApi } from "./s25.multiselect.results.component";

export class SearchCriteriaDataUtil {
    public static mergeItemsInfo = (sources: S25ItemI[], targets: S25ItemI[]) => {
        const targetDict = S25Util.fromEntries(targets.map((target) => [target.itemId, target]));
        sources.forEach((source) => {
            let target = targetDict[source.itemId];
            if (target) {
                target.favorite = source.favorite;
                target.itemDesc = source.itemDesc;
                target.itemName = target.itemName || source.itemName;
                const intermediate = Object.assign({}, source, target);
                Object.assign(target, intermediate);
            }
        });
    };

    public static searchCriteriaSetup(
        multiSelectBean?: MultiselectModelI,
        searchContext?: any, // SearchCriteriaType["type"],
        selectedItems?: S25ItemI[],
        elem?: any,
        honorMatching?: boolean,
        permanentCustomFilterValue?: string,
        useSecurity?: boolean,
    ) {
        let itemsMerged = false;
        multiSelectBean = multiSelectBean || {};
        let origOnInit =
            multiSelectBean.onInit ||
            function () {
                return jSith.when();
            };
        selectedItems = selectedItems || [];
        honorMatching = honorMatching || false; //default to false; unless true, matching bean and show matching are NOT included in the multiselect model; if true and the criteria bean has it configured, then it will show
        let ret = jSith.defer();
        S25Util.coalesceDeep(multiSelectBean, searchContext); //inherit searchContext settings but prefer initial modelBean properties if present
        if (!honorMatching) {
            multiSelectBean.showMatching = false;
        }
        multiSelectBean.dataDefer = jSith.defer();
        multiSelectBean.dataPromise = multiSelectBean.dataDefer.promise; //data promise so that infinite scroll can wait until data is loaded

        if (multiSelectBean.domainFilter) {
            //get options to choose on multiselect, eg for events, a list of searches
            multiSelectBean.domainFilter.promise = multiSelectBean.domainFilter.filterMethod().then((data: any) => {
                data = (S25Util.array.isArray(data) && data) || multiSelectBean.extractItems(data);
                multiSelectBean.domainFilter.options = [{ itemName: "All filters", itemId: -999 }].concat(data);
            });
        }

        let resetPagination = (data: any) => {
            //function to reset pagination when user makes a new type of filter
            multiSelectBean.pageCount = data.searchCriteria.page_count;
            multiSelectBean.cacheId = data.searchCriteria.cache_id;
            multiSelectBean.page = 1; //page was 0, now we got the first page, so we increment to 1
        };

        let refresh = (doPageReset: boolean, setItems: boolean, isMultiselectRefresh: boolean, data: any) => {
            doPageReset && resetPagination(data); //each filter data call resets the pages
            if (setItems) {
                multiSelectBean.items =
                    multiSelectBean.items ||
                    (multiSelectBean.extractItems && multiSelectBean.extractItems(data)) ||
                    data;
                if (!multiSelectBean.origItems) multiSelectBean.origItems = multiSelectBean.items;
                if (!itemsMerged) {
                    itemsMerged = true;
                    SearchCriteriaDataUtil.mergeItemsInfo(multiSelectBean.items, multiSelectBean.selectedItems);
                    multiSelectBean.showResult && MultiselectResultsApi.refresh(document.body, multiSelectBean.uuid);
                }
            }
            let resp = isMultiselectRefresh && MultiselectApi.refresh(document.body, multiSelectBean.uuid);
            resp = (resp && resp.then && resp) || jSith.when();
            resp.then(() => {
                MultiselectApi.forceScrollAction(document.body, multiSelectBean.uuid);
            });
            multiSelectBean.dataDefer.resolve(); //multiselect done method will reset this
            return (multiSelectBean.extractItems && multiSelectBean.extractItems(data)) || data;
        };

        if (multiSelectBean.hasScroll) {
            //if infinite scroll
            let skipFirstShow = false; //we may want to prefetch items before the user clicks to show the multiselect, that is what this is used for
            //multiSelectBean.columns = 4; //set multiselect to have 4 cols by default (all of these selections have many elements)
            multiSelectBean.page = 0; //set initial page info
            multiSelectBean.pageCount = 1;
            multiSelectBean.pageSize = 100;
            multiSelectBean.cacheId = 0;
            multiSelectBean.filter = undefined;

            let hasMoreF = () => {
                //infinite scroll uses this to determine if more pages exist
                return multiSelectBean.page < multiSelectBean.pageCount;
            };

            multiSelectBean.hasMoreF = hasMoreF; //set this bc multiselect uses it via its modelBean

            multiSelectBean.scrollF = (callback?: Function) => {
                //action on scroll
                callback = callback || function () {};
                let callbackF = function () {
                    //callback function to refresh multiselect
                    callback();
                    MultiselectApi.refresh(document.body, multiSelectBean.uuid);
                };
                if (hasMoreF()) {
                    //if more pages
                    return multiSelectBean
                        .serviceMethod(multiSelectBean.cacheId, multiSelectBean.page, multiSelectBean.pageSize)
                        .then((data: any) => {
                            //get paginated data
                            let items = multiSelectBean.extractItems(data); //extract items from data
                            if (items && items.length > 0) {
                                multiSelectBean.page++; //increment page number
                                multiSelectBean.items = multiSelectBean.items.concat(items); //set items of this next page
                                return callbackF(); //run callback
                            } else {
                                multiSelectBean.pageCount = 0; //no more pages
                                return callbackF(); //run callback
                            }
                        });
                } else {
                    //no more pages
                    return jSith.when(callbackF()); //still run callback
                }
            };

            multiSelectBean.serverSideFilter = (filter) => {
                //convenience function for multiselect to call when filtering based on a string (name)
                //save page/search request in a promise, falling back to [] if not exists
                //this serializes the async requests so we can keep pagination and results data straight
                multiSelectBean.currentPromises = multiSelectBean.currentPromises || [];

                //set new pagination meta data
                multiSelectBean.filter = filter;
                multiSelectBean.cacheId = 0; //run a new search
                multiSelectBean.page = 0; //run from page 0 since this is a new filter

                //make call immediately
                let promise = multiSelectBean.serviceMethod(
                    multiSelectBean.cacheId,
                    multiSelectBean.page,
                    multiSelectBean.pageSize,
                    multiSelectBean.filter,
                    multiSelectBean.filterFavorites,
                    multiSelectBean.domainFilter,
                    (permanentCustomFilterValue || "") + (multiSelectBean.customFilterValue || ""),
                );

                //note we must immediately push to the array so the next caller, if any, can check if it is the last request so far
                multiSelectBean.currentPromises.push(promise); //push to promise array

                return promise.then((data: any) => {
                    //when promise finishes
                    let len = multiSelectBean.currentPromises.length;
                    //check this promise was the last one, if so, return data, else do nothing
                    if (len > 0 && promise === multiSelectBean.currentPromises[len - 1]) {
                        return jSith.when(refresh(true, false, false, data)); //return items
                    } else {
                        return jSith.when();
                    }
                });
            };

            multiSelectBean.onInit = () => {
                //multiselect runs this onInit
                return origOnInit().then(() => {
                    if (multiSelectBean.expensiveSearch) {
                        //for expensive searches, each search starts with its selected items
                        multiSelectBean.items = multiSelectBean.selectedItems || [];
                        MultiselectApi.refresh(document.body, multiSelectBean.uuid);
                        return jSith.when();
                    } else {
                        if (!skipFirstShow) {
                            //if skipFirstShow true, then skip first first show
                            return multiSelectBean
                                .serviceMethod(
                                    multiSelectBean.cacheId,
                                    multiSelectBean.page,
                                    multiSelectBean.pageSize,
                                    multiSelectBean.filter,
                                    multiSelectBean.filterFavorites,
                                    multiSelectBean.domainFilter,
                                    multiSelectBean.omitReadOnly,
                                )
                                .then((data: any) => {
                                    //get any new data into modelBean
                                    return refresh(true, true, true, data);
                                });
                        } else {
                            //do not skip further shows
                            skipFirstShow = false;
                        }
                    }
                });
            };

            multiSelectBean.items = []; //need to set to empty array to set selectedItems before we run data ajax call
            multiSelectBean.selectedItems = selectedItems; //set selectedItems
            multiSelectBean.preFetchItems &&
                multiSelectBean.onInit().then(() => {
                    //if context has selectedItems, then run data before Edit click, and resolve promise
                    skipFirstShow = true;
                    ret.resolve(); //if pre fetching, wait to resolve return promise until prefetch done
                });
            if (!multiSelectBean.preFetchItems) {
                ret.resolve();
            } //flag init done in scope for convenience
        } else {
            //if here, then we do not have to mess with infinite scroll
            multiSelectBean.items = []; //need to set to empty array to set selectedItems before we run data ajax call
            multiSelectBean.isItemsInit = false; //flag the fact that the first set of items was empty (so make the ajax call onInit)
            multiSelectBean.selectedItems = selectedItems; //set selectedItems
            let initialSelectedItems = S25Util.deepCopy(multiSelectBean.selectedItems); //save initially selected items

            multiSelectBean.onInit = () => {
                return origOnInit().then(function () {
                    S25LoadingApi.openOnBody("ngSearchCriteriaLoading", "Loading..."); //show loading when opening multiselect
                    if (!multiSelectBean.isItemsInit) {
                        multiSelectBean.isItemsInit = true;
                        multiSelectBean.items = undefined;
                    }
                    let promise = multiSelectBean.items
                        ? jSith.when(multiSelectBean.items)
                        : multiSelectBean.serviceMethod(
                              !searchContext.expensiveSearch && useSecurity,
                              null,
                              permanentCustomFilterValue,
                          ); //if modelItems exist, use them, else call service for them
                    return promise.then((data: any) => {
                        //get any new data into modelBean
                        S25LoadingApi.closeOnBody("ngSearchCriteriaLoading"); //close loading when promise resolved

                        //keep selectedItem properties added later on initial selected items (like isPermanent)
                        jSith.forEach(multiSelectBean.selectedItems, (_: any, selectedItem) => {
                            jSith.forEach(initialSelectedItems, (_: any, initialSelectedItem) => {
                                if (initialSelectedItem.itemId == selectedItem.itemId) {
                                    initialSelectedItem.isPermanent = selectedItem.isPermanent;
                                    initialSelectedItem.isSecret = selectedItem.isSecret;
                                }
                            });
                        });

                        //always include initial selected items in full items data in case they were excluded for some reason from the GET for all items
                        data = S25Util.array.uniqueByProp(data.concat(initialSelectedItems), "itemId");
                        return refresh(false, true, true, data);
                    });
                });
            };

            ret.resolve();
        }

        //return promise
        return S25Util.all([
            ret.promise,
            (multiSelectBean.domainFilter && multiSelectBean.domainFilter.promise) || jSith.when(),
        ]);
    }
}
